<template>
  <div>
    <b-modal
      v-model="showVendorInfo"
      size="lg"
      centered
      hide-footer
      @hide="hidePopup"
    >
      <ApplicationInputs :data="selectedVendor" />
    </b-modal>

    <b-card
      :header="$i18n.t('navigation.vendors')"
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <!-- filters and search -->
      <FiltersAndSearch
        :id.sync="id"
        :searchKey.sync="searchKey"
        :activeOnly.sync="activeOnly"
        :sortOrder.sync="sortOrder"
      />
      <!-- end of filters and search -->
      <!-- export -->
      <ExportFromTo :exportHandler="exportVendorsHandler" class="mb-2"/>
      <!-- end export -->
      <!-- table -->
      <b-table
        sticky-header
        :fields="columns"
        :items="vendorsList"
        class="shadow-sm rounded"
        small
        responsive
        hover
        bordered
        :busy="isTableBusy"
        empty-text="No matching records found"
        @row-clicked="onRowClick"
      >
        <template #table-busy>
          <div class="text-center text-danger my-5">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <!-- Column: Action -->
        <template #cell(action)="data">
          <div v-if="isDeleteAllowed" class="d-flex flex-nowrap">
            <b-button
              v-b-tooltip.hover.bottom="'Delete'"
              variant="flat-danger"
              class="btn-icon"
              @click="onDeleteVendor(data.item.id)"
            >
              <feather-icon size="16" icon="TrashIcon" />
            </b-button>
          </div>
          <div v-if="!areActionsAllowed && isSupport">
            <b-button
              v-b-tooltip.hover.bottom="'Password reset'"
              class="btn-icon"
              variant="flat-success"
              @click="onResetPassword(data.item.id)"
            >
              <feather-icon size="16" icon="KeyIcon" />
            </b-button>
          </div>
          <div v-if="areActionsAllowed">
            <div class="d-flex flex-nowrap">
              <input
                :ref="`${data.item.id}-file`"
                type="file"
                style="display: none"
                accept="application/pdf"
                @change="(e) => onFilePicked(e, data.item.id)"
              />
              <b-button
                v-b-tooltip.hover.bottom="$t('vendors.contract-upload')"
                class="btn-icon"
                variant="flat-primary"
                @click="onPickFile(data.item.id)"
              >
                <feather-icon size="16" icon="UploadIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="$t('vendors.attachment-download')"
                class="btn-icon"
                variant="flat-primary"
                @click="onGetAttachement(data.item.id)"
              >
                <feather-icon size="16" icon="PaperclipIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="$t('vendors.password-reset')"
                class="btn-icon"
                variant="flat-success"
                @click="onResetPassword(data.item.id)"
              >
                <feather-icon size="16" icon="KeyIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="$t('vendors.delete')"
                variant="flat-danger"
                class="btn-icon"
                @click="onDeleteVendor(data.item)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom="$t('vendors.impersonate')"
                class="btn-icon"
                variant="flat-info"
                @click="impersonate(data.item.email)"
              >
                <feather-icon size="16" icon="UserIcon" />
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
      <Pagination
        :fetch-data="getVendorsList"
        :total-rows="totalRows"
        :take.sync="take"
        :skip.sync="skip"
      />
    </b-card>
  </div>
</template>

<script>
import { BButton, BTable, BCard, VBTooltip, BSpinner } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { mapActions, mapState } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import isAllowed from "@core/utils/isAllowed";
import Swal from "sweetalert2";
import Pagination from "@/views/components/Pagination/Pagination.vue";
import FiltersAndSearch from "@/views/components/FiltersAndSearch/FiltersAndSearch.vue";
import ApplicationInputs from "./ApplicationInputs.vue";
import ExportFromTo from "../components/ExportFromTo/ExportFromTo.vue";

const FileSaver = require("file-saver");

export default {
  components: {
    Pagination,
    FiltersAndSearch,
    ApplicationInputs,
    BButton,
    BTable,
    BCard,
    BSpinner,
    ExportFromTo,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      vendorsList: [],
      action: null,
      selectedVendor: {},
      showVendorInfo: false,
      imageUrl: null,
      sortOrder: 2,
      id: "",
      searchKey: "",
      activeOnly: true,
      totalRows: 0,
      take: 20,
      skip: 0,
    };
  },
  computed: {
    ...mapState("vendorManagement", [
      "vendors",
      "redirectUrl",
      "contractData",
      "isTableBusy",
    ]),
    isSupport() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Support"], roles);
    },
    areActionsAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor"], roles);
    },
    isDeleteAllowed() {
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      console.log(isAllowed(["Category Manager"], roles), roles);
      return isAllowed(["Category Manager"], roles);
    },
    attachmentStatus() {
      return {
        0: "Not uploaded",
        1: "Uploaded",
        2: "Signed",
      };
    },
    columns() {
      return [
        {
          label: this.$t("vendors.id"),
          key: "customId",
        },
        {
          label: this.$t("application.brand-name-geo"),
          key: "brandNameGeo",
        },
        {
          label: this.$t("application.brand-name-eng"),
          key: "brandNameEng",
        },
        {
          label: this.$t("application.contact-person-name"),
          key: "contactPerson",
        },
        {
          label: this.$t("application.legal-address"),
          key: "legalAddress",
        },
        {
          label: this.$t("application.mobile"),
          key: "mobile",
        },
        {
          label: this.$t("application.email"),
          key: "email",
        },
        {
          label: this.$t("application.status"),
          key: "contractAttachmentStatus",
          formatter: (value) => `${this.attachmentStatus[value]}`,
        },
        {
          label: this.areActionsAllowed ? this.$t("global.action") : "",
          key: "action",
        },
      ];
    },
  },
  watch: {
    searchKey() {
      this.getVendorsList();
    },
    activeOnly() {
      this.getVendorsList();
    },
    id() {
      this.getVendorsList();
    },
    take() {
      this.getVendorsList();
    },
    sortOrder() {
      this.getVendorsList();
    },
  },
  created() {
    this.getVendorsList();
  },
  methods: {
    ...mapActions("vendorManagement", [
      "getVendors",
      "resetVendorPassword",
      "deleteVendor",
      "getImpersonateUrl",
      "setVendorAttachement",
      "getVendorAttachement",
      "exportVendors",
    ]),

    async getVendorsList() {
      try {
        await this.getVendors({
          skip: this.skip,
          take: this.take,
          searchKey: this.searchKey,
          activeOnly: this.activeOnly,
          sortOrder: this.sortOrder,
          id: this.id,
        });
        this.vendorsList = this.vendors.data.vendors;
        this.totalRows = this.vendors.data?.count;
      } catch (err) {
        console.log(err);
      }
    },
    onPickFile(id) {
      this.$refs[`${id}-file`].click();
    },
    async onFilePicked(event, id) {
      const { files } = event.target;

      const fileReader = new FileReader();

      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });

      fileReader.readAsDataURL(files[0]);

      const formData = new FormData();

      formData.append("contractAttachment", files[0]);

      try {
        await this.setVendorAttachement({ id, formData });
        this.getVendorsList();
        this.toast("File sucessfully uploaded");
      } catch (error) {
        console.log(error);
      }
    },
    hidePopup() {
      this.showVendorInfo = false;
    },
    onRowClick(item) {
      this.selectedVendor = item;
      this.showVendorInfo = true;
    },
    toast(message = "Action Success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "FileIcon",
          variant: type,
        },
      });
    },
    async exportVendorsHandler(from, to) {
      const payload = { from, to };

      try {
        const response = await this.exportVendors(payload);
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Vendors-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
    async onGetAttachement(id) {
      try {
        const response = await this.getVendorAttachement(id);
        if (response.data.data.url) {
          window.open(response.data.data.url, "_blank");
        } else {
          this.toast("No file uploaded", "danger");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async impersonate(email) {
      try {
        await this.getImpersonateUrl(email);
        window.open(this.redirectUrl, "_blank");
      } catch (err) {
        console.log(err);
      }
    },
    async onDeleteVendor(vendor) {
      const result = await Swal.fire({
        title: `Are you sure you want to delete vendor - ${vendor.brandNameGeo} / ${vendor.brandNameEng}?`,
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      });

      if (result.isConfirmed) {
        try {
          await this.deleteVendor({ id: vendor.id });
          this.toast("Vendor deleted successfully");
          this.getVendorsList();
        } catch (err) {
          console.log(err);
        }
      }
    },
    async onResetPassword(vendorId) {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
      });

      if (result.isConfirmed) {
        try {
          await this.resetVendorPassword({ id: vendorId });
          this.toast("Password reset successfully");
        } catch (err) {
          console.log("error", err);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}
.form-group {
  @media (max-width: 600px) {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
</style>
