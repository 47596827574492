<template>
  <div>
    <div class="d-flex justify-content-between">
      <div v-if="data.logoUrl">
        <p class="label">{{ t.t("application.logo") }}</p>
        <img :src="data.logoUrl" alt="logo" class="app-logo" />
      </div>
      <div v-if="data.coverUrl" class="">
        <p class="label">{{ t.t("application.cover") }}</p>
        <img :src="data.coverUrl" alt="logo" class="app-logo" />
      </div>
    </div>
    <hr class="my-2" />
    <div class="grid-2 grid-cols-2 ">
      <div v-for="item in vendorData" :key="item.label">
        <p class="label">{{ item.label }}</p>
        <a v-if="item.hasLink" class="value text-truncate d-block mb-2" target="_blank" :href="item.value">{{ item.value }}</a>
        <p v-else class="value">{{ item.value }}</p>
      </div>
      <div>
        <p class="label">{{ t.t("application.branches") }}</p>
        <p v-for="branch in data.branches" :key="branch.id" class="mb-0">
          {{ branch.nameGeo }} / {{ branch.nameEng }}
        </p>
      </div>
      <div>
        <p class="label">{{ t.t("application.segments") }}</p>
        <div v-for="segment in data.segments" :key="segment.id">
          <p class="mb-0">
            {{ segment.categoryNameGeo }} / {{ segment.categoryNameEng }}
          </p>
          <p class="ml-2">
            {{ segment.subCategoryNameGeo }} /
            {{ segment.subCategoryNameEng }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      vendorType: {
        1: "Legal",
        2: "Physical",
      },
      t: null,
    };
  },
  computed: {
    vendorData() {
      return [
        {
          label: this.$i18n.t("application.fullName"),
          value: this.data.legalNameOrFullName,
        },
        {
          label: this.$i18n.t("application.vendorType"),
          value: this.vendorType[this.data.vendorType],
        },
        {
          label: this.$i18n.t("application.contact-person-name"),
          value: this.data.contactPerson,
        },
        {
          label: this.$i18n.t("application.identification"),
          value: this.data.identificationCode,
        },
        {
          label: this.$i18n.t("application.mobile"),
          value: this.data.mobile,
        },
        {
          label: this.$i18n.t("application.contact-person-position"),
          value: this.data.contactPersonPosition,
        },
        {
          label: this.$i18n.t("application.contact-person-mobile"),
          value: this.data.contactPersonMobile,
        },
        {
          label: this.$i18n.t("application.brand-name-geo"),
          value: this.data.brandNameGeo,
        },
        {
          label: this.$i18n.t("application.brand-name-eng"),
          value: this.data.brandNameEng,
        },
        {
          label: this.$i18n.t("application.email"),
          value: this.data.email,
        },
        {
          label: this.$i18n.t("application.legal-address"),
          value: this.data.legalAddress,
        },
        {
          label: this.$i18n.t("application.physical-address"),
          value: this.data.physicalAddress,
        },
        {
          label: this.$i18n.t("application.director-fullName"),
          value: this.data.directorFullName,
        },
        {
          label: this.$i18n.t("application.director-phone"),
          value: this.data.directorMobile,
        },
        {
          label: this.$i18n.t("application.accountant-fullName"),
          value: this.data.accountantFullName,
        },
        {
          label: this.$i18n.t("application.accountant-phone"),
          value: this.data.accountantMobile,
        },
        {
          label: this.$i18n.t("application.iban"),
          value: this.data.iban,
        },
        {
          label: this.$i18n.t("application.is-vat-payer"),
          value: this.data.isVatPayer
            ? this.$i18n.t("global.yes")
            : this.$i18n.t("global.no"),
        },
        {
          label: this.$i18n.t("application.other-contact-person-mobile"),
          value: this.data.otherContactPersonMobile,
        },
        {
          label: this.$i18n.t("application.warehouse-address"),
          value: this.data.warehouseAddress,
        },
        {
          label: this.$i18n.t("application.description-geo"),
          value: this.data.descriptionGeo,
        },
        {
          label: this.$i18n.t("application.description-eng"),
          value: this.data.descriptionEng,
        },
        {
          label: this.$i18n.t("application.web-page"),
          value: this.data.webPage,
        },
        {
          label: this.$i18n.t("application.facebook"),
          value: this.data.facebookLink,
          hasLink: true,
        },
        {
          label: this.$i18n.t("application.instagram"),
          value: this.data.instagramLink,
          hasLink: true,
        },
      ];
    },
  },
  created() {
    this.t = this.$i18n;
  },
};
</script>
<style lang="scss" scoped>
.last-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.branches-segments-wrapper {
  display: flex;
}

.app-logo {
  max-height: 125px;
  margin: 0.5rem;
  border-radius: 15px;
}

.grid-2 {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 0 20px;
}
.label {
  font-weight: bold;
  margin: 0;
  color: gray;
}
</style>
